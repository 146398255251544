<div class="flex justify-content-center text-white bg-blue">
    <div class="py-6 px-4 sm:px-5 sm:p-6 w-full" style="max-width: 1300px">
        <div class="flex flex-row justify-content-between flex-wrap">
            <div class="flex flex-column w-6 lg:w-auto mb-5 lg:mb-0 text-center lg:text-left px-2 sm:px-0">
                <div class="font-bold text-lg sm:text-xl">{{ "footer.menu" | translate }}</div>
                <div class="flex flex-column gap-1 pt-2 text-base sm:text-lg font-l-light cursor-pointer">
                    <div (click)="navTo('/aboutus/sainfo')" class="hover:text-primary-100">{{ "footer.menu1" | translate
                        }}</div>
                    <div (click)="navTo('/business/main-real-estate')" class="hover:text-primary-100">{{ "footer.menu2"
                        | translate }}</div>
                    <div (click)="navTo('/sustainability/environment')" class="hover:text-primary-100">{{
                        "footer.menu11"
                        | translate }}</div>
                    <div (click)="navTo('/news-events/all-news')" class="hover:text-primary-100">{{ "footer.menu3" |
                        translate }}</div>
                    <!-- <div (click)="navTo('/business/main-real-estate')" class="hover:text-primary-100">{{ "footer.menu4" | translate }}</div> -->
                    <div (click)="navTo('/tools/installment-tool')" class="hover:text-primary-100">{{ "footer.menu5" |
                        translate }}</div>
                    <a href="https://investor.siameseasset.co.th/th/home" target="_blank" rel="noopener noreferrer"
                        class="text-white hover:text-primary-100 no-underline">{{ "footer.menu6" | translate }}</a>
                    <a href="https://www.siameseprivilege.com/" target="_blank" rel="noopener noreferrer"
                        class="text-white hover:text-primary-100 no-underline">{{ "footer.menu10" | translate }}</a>
                    <!-- <div (click)="navTo('/career/sa-career')" class="hover:text-primary-100">{{ "footer.menu8" |
                        translate }}</div> -->
                    <div (click)="navTo('/contact/all-contact')" class="hover:text-primary-100">{{ "footer.menu9"
                        | translate }}</div>
                </div>
            </div>
            <div
                class="flex flex-column w-6 lg:w-auto mb-5 lg:mb-0 gap-5 lg:gap-8 text-center lg:text-left px-2 sm:px-0">
                <div class="flex flex-column">
                    <div class="font-bold text-lg sm:text-xl">{{ "footer.condo" | translate }}</div>
                    <div
                        class="flex flex-column gap-1 pt-2 text-base sm:text-lg font-light cursor-pointer font-l-light">
                        <div (click)="navTo('/business/condominium')" class="hover:text-primary-100">{{ "footer.condo1"
                            | translate }}</div>
                        <!-- <div (click)="navTo('/business/condominium')" class="hover:text-primary-100">{{ "footer.condo2"
                            | translate }}</div> -->
                    </div>
                </div>
                <div class="flex flex-column">
                    <div class="font-bold text-lg sm:text-xl">{{ "footer.other" | translate }}</div>
                    <div
                        class="flex flex-column gap-1 pt-2 text-base sm:text-lg font-light cursor-pointer font-l-light">
                        <div (click)="navTo('/business/hotel')" class="hover:text-primary-100">{{ "footer.other1" |
                            translate }}</div>
                        <div (click)="navTo('/business/food-beverage')" class="hover:text-primary-100">{{
                            "footer.other2" | translate }}</div>
                        <div (click)="navTo('/business/health-wellness')" class="hover:text-primary-100">{{
                            "footer.other3" | translate }}</div>
                        <div (click)="navTo('/business/technology')" class="hover:text-primary-100">{{ "footer.other4" |
                            translate }}</div>
                        <div (click)="navTo('/business/finance-investment')" class="hover:text-primary-100">{{
                            "footer.other5" | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-column w-6 lg:w-auto text-center lg:text-left px-2 sm:px-0">
                <div class="font-bold text-lg sm:text-xl">{{ "footer.house" | translate }}</div>
                <div class="flex flex-column gap-1 pt-2 text-base sm:text-lg font-light cursor-pointer">
                    <div (click)="navTo('/business/house')" class="hover:text-primary-100">{{ "footer.house1" |
                        translate }}</div>
                    <!-- <div (click)="navTo('/business/house')" class="hover:text-primary-100">{{ "footer.house2" |
                        translate }}</div> -->
                </div>
            </div>
            <div
                class="flex flex-column justify-content-between font-bold gap-8 cursor-pointer w-6 lg:w-auto text-center lg:text-left px-2 sm:px-0">
                <div class="flex flex-column">
                    <div class="font-bold text-lg sm:text-xl">{{ "footer.townhome" | translate }}</div>
                    <div class="flex flex-column gap-1 pt-2 text-base sm:text-lg font-light cursor-pointer">
                        <div (click)="navTo('/business/townhome')" class="hover:text-primary-100">{{ "footer.townhome1"
                            | translate }}</div>
                        <!-- <div (click)="navTo('/business/townhome')" class="hover:text-primary-100">{{ "footer.townhome2"
                            | translate }}</div> -->
                    </div>
                </div>
                <div class="lg:flex flex-column hidden justify-content-end align-items-end">
                    <div class="flex flex-row gap-3">
                        <a class="hover:text-primary-100 text-white" href="https://www.facebook.com/SiameseAssetPCL"
                            target="_blank" rel="noopener noreferrer">
                            <i class="pi pi-facebook" style="font-size: 2rem"></i>
                        </a>
                        <a class="hover:text-primary-100 text-white" href="https://www.instagram.com/siameseassetcorp/"
                          target="_blank" rel="noopener noreferrer">
                            <i class="pi pi-instagram cursor-pointer hover:text-primary-100"
                            style="font-size: 2rem"></i>
                        </a>

                        <a class="hover:text-primary-100 text-white" href="https://x.com/siameseasset?lang=en"
                            target="_blank" rel="noopener noreferrer">
                            <div class="w-2rem h-2rem relative">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path class="hoverIcon" fill="#ffffff"
                                        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                </svg>
                            </div>
                        </a>
                        <a class="hover:text-primary-100 text-white" href="https://lin.ee/D8o1cne" target="_blank"
                            rel="noopener noreferrer">
                            <div class="w-2rem h-2rem ">
                               <div  class ="image-default"></div>
                            </div>
                        </a>
                       
                       
                        <a class="hover:text-primary-100 text-white" href="https://www.youtube.com/@SiameseAssetCorp"
                            target="_blank" rel="noopener noreferrer">
                            <i class="pi pi-youtube cursor-pointer hover:text-primary-100" style="font-size: 2rem"></i>
                        </a>
                    </div>
                    <div class="text-xl py-3">SIAMESE ASSET CONNECT</div>
                    <a href="tel:1306" class="flex flex-row align-items-center">
                        <img class="w-10rem cursor-pointer hover:text-primary-100" src="/assets/logo/phone.png"
                            alt="" />
                    </a>
                </div>
            </div>
            <div class="flex flex-column lg:hidden justify-content-end align-items-center mt-6 w-full">
                <div class="flex flex-row gap-3">
                    <a class="hover:text-primary-100  text-white" href="https://www.facebook.com/SiameseAssetPCL"
                         target="_blank" rel="noopener noreferrer">
                        <i class="pi pi-facebook" style="font-size: 2rem"></i>
                    </a>

                       
                    <a class="hover:text-primary-100 text-white" href="https://www.instagram.com/siameseassetcorp/"
                        target="_blank" rel="noopener noreferrer">
                        <i class="pi pi-instagram cursor-pointer hover:text-primary-100" style="font-size: 2rem"></i>
                    </a>


                    <a class="hover:text-primary-100 text-white" href="https://x.com/siameseasset?lang=en"
                        target="_blank" rel="noopener noreferrer">
                        <div class="w-2rem h-2rem">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path class="hoverIcon" fill="#ffffff"
                                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                            </svg>
                        </div>
                    </a>

                    <a class="hover:text-primary-100 text-white" href="https://lin.ee/D8o1cne" target="_blank"
                        rel="noopener noreferrer">
                        <div class="w-2rem h-2rem">
                            <div class="w-2rem h-2rem ">
                                <div  class ="image-default"></div>
                             </div>
                        </div>
                    </a>

                    <a class="hover:text-primary-100 text-white" href="https://www.youtube.com/@SiameseAssetCorp"
                        target="_blank" rel="noopener noreferrer">
                        <i class="pi pi-youtube cursor-pointer hover:text-primary-100" style="font-size: 2rem"></i>
                    </a>
                    
                </div>
                <div class="text-xl py-3">SIAMESE ASSET CONNECT</div>
                <a href="tel:1306" class="flex flex-row align-items-center">
                    <img class="w-10rem cursor-pointer" src="/assets/logo/phone.png" alt="" />
                </a>
            </div>
        </div>
    </div>
</div>
<div class="bg-white p-5 flex flex-column justify-content-center align-items-center text-color text-center">
    <img (click)="navTo('/')" class="w-12rem sm:w-15rem cursor-pointer" src="https://new-corp.sgp1.cdn.digitaloceanspaces.com/logos-icons/new-logo-v3.png"
        alt="" />
    <div class="text-lg sm:text-xl font-bold line-height-2">SIAMESE ASSET PUBLIC COMPANY LIMITED
    </div>
    <div class="text-sm font-light pb-1 mt-2 font-m-gotham line-height-2">Copyright © 2019 All rights reserved. Created
        by SIAMESE ASSET PUBLIC COMPANY
        LIMITED. ALL RIGHTS RESERVED</div>
</div>