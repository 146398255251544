<div class="flex flex-column text-xs sm:text-base">
    <div class="flex flex-column justify-content-between w-full mb-2 gap-3 text-base sm:text-lg">
        <div class="flex flex-column w-full gap-3">
            <div class="cursor-pointer hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="aboutMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveAbout,
                    'text-900': !sectionIsActiveAbout
                }">{{ "header.aboutus" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openAboutMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openAboutMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column gap-3" *ngIf="openAboutMenu">
                <a (click)="navTo('/aboutus/sainfo')" routerLink="/aboutus/sainfo" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.aboutus1" | translate }}
                </a>
                <a (click)="navTo('/aboutus/vandm')" routerLink="/aboutus/vandm" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.aboutus2" | translate }}
                </a>
                <!-- <a (click)="navTo('/aboutus/journey')" routerLink="/aboutus/journey" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.aboutus3" | translate }}
                </a> -->
                <div (click)="navTo('/aboutus/awards')" routerLink="/aboutus/awards" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.aboutus4" | translate }}
                </div>
                <div (click)="navTo('/aboutus/letter')" routerLink="/aboutus/letter" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.aboutus5" | translate }}
                </div>
                <div (click)="navTo('/aboutus/board')" routerLink="/aboutus/board" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.aboutus6" | translate }}
                </div>
            </div>
        </div>
        <div class="flex flex-column w-full gap-3">
            <div class="cursor-pointer hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="businessMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveBusiness,
                    'text-900': !sectionIsActiveBusiness
                }">{{ "header.business" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openBusinessMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openBusinessMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column gap-1" *ngIf="openBusinessMenu">
                <!-- <div (click)="navTo('/business/main-real-estate')" routerLink="/business/main-real-estate"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "menuHeader.property" | translate }}</span>
                </div> -->
                <div (click)="navTo('/business/branded-residence')" routerLink="/business/branded-residence"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "menuHeader.property1" | translate }}</span>
                </div>
                <div (click)="navTo('/business/condominium')" routerLink="/business/condominium"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "menuHeader.property2" | translate }}</span>
                </div>
                <div (click)="navTo('/business/house')" routerLink="/business/house" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "menuHeader.property3" | translate }}</span>
                </div>
                <div (click)="navTo('/business/townhome')" routerLink="/business/townhome"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "menuHeader.property4" | translate }}</span>
                </div>
                <!-- <div (click)="navTo('/business/main-others')" routerLink="/business/main-others"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other" | translate }}</span>
                </div> -->
                <div (click)="navTo('/business/hotel')" routerLink="/business/hotel" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other1" | translate }}</span>
                </div>
                <div (click)="navTo('/business/food-beverage')" routerLink="/business/food-beverage"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other2" | translate }}</span>
                </div>
                <div (click)="navTo('/business/health-wellness')" routerLink="/business/health-wellness"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other3" | translate }}</span>
                </div>
                <div (click)="navTo('/business/technology')" routerLink="/business/technology"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other4" | translate }}</span>
                </div>
                <div (click)="navTo('/business/finance-investment')" routerLink="/business/finance-investment"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other5" | translate }}</span>
                </div>
                <!-- <div (click)="navTo('/business/leasing')" routerLink="/business/leasing" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 mb-2 flex justify-content-between no-underline">
                    <span>{{ "footer.other6" | translate }}</span>
                </div> -->
            </div>
        </div>
    </div>
    <div class="flex flex-column justify-content-between w-full my-2 gap-3">
        <div class="flex flex-column w-full text-base sm:text-lg">
            <div [ngClass]="{'fontS flex align-items-end h-2rem': selectedLang === 'EN'}"
                (click)="navTo('/sustainability/environment')" routerLink="/sustainability/"
                routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: false}"
                class="cursor-pointer text-900 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                {{ "header.sustain" | translate }}
            </div>
        </div>
        <div class="flex flex-column w-full text-base sm:text-lg">
            <div class="cursor-pointer  hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="eventMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveEvent,
                    'text-900': !sectionIsActiveEvent
                }">{{ "header.event" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openEventMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openEventMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column gap-3 mt-3" *ngIf="openEventMenu">
                <div (click)="navTo('/news-events/all-news')" routerLink="/news-events/all-news"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.new1" | translate }}
                </div>
                <!-- <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.new2" | translate }}
                    <span class="flex align-items-center">
                        <i class="pi pi-arrow-up-right text-primary" style="font-size: 1rem;"></i>
                    </span>
                </div> -->
                <div (click)="navTo('/news-events/all-promotions')" routerLink="/news-events/all-promotions"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.new3" | translate }}
                </div>
                <!-- <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "header.blog" | translate }}
                    <span class="flex align-items-center">
                        <i class="pi pi-arrow-up-right text-primary ml-2" style="font-size: 1rem;"></i>
                    </span>
                </div> -->
            </div>
        </div>
        <!-- <div class="flex flex-column w-full">
            <div class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="developMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveDevelop,
                    'text-900': !sectionIsActiveDevelop
                }">{{ "header.develop" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openDevelopMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openDevelopMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column gap-3 mt-3" *ngIf="openDevelopMenu">
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.develop1" | translate }}
                </div>
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.develop2" | translate }}
                </div>
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.develop3" | translate }}
                </div>
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.develop4" | translate }}
                </div>
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.develop5" | translate }}
                </div>
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.develop6" | translate }}
                </div>
            </div>
        </div> -->
    </div>
    <div class="flex flex-column justify-content-between w-full my-2 gap-3">
        <div class="flex flex-column w-full text-base sm:text-lg">
            <div class="cursor-pointer  hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="toolMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveTool,
                    'text-900': !sectionIsActiveTool
                }">{{ "header.tool" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openToolMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openToolMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column mt-3 gap-3" *ngIf="openToolMenu">
                <div (click)="navTo('/tools/installment-tool')" routerLink="/tools/installment-tool"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.tool1" | translate }}
                </div>
                <a href="https://app.siameseasset.co.th/onlinepayment/" target="_blank" rel="noopener noreferrer"
                    class="cursor-pointer no-underline text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.tool2" | translate }}
                </a>
                <a href="https://app.siameseasset.co.th/sabox/" target="_blank" rel="noopener noreferrer"
                    class="cursor-pointer no-underline text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.tool3" | translate }}
                </a>
            </div>
        </div>
        <div class="flex flex-column w-full text-base sm:text-lg">
            <a [ngClass]="{
                'fontS flex align-items-end h-2rem': selectedLang === 'EN'}" target="_blank" rel="noopener noreferrer"
                href="https://investor.siameseasset.co.th/th/home"
                class="cursor-pointer text-900 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                {{ "header.investor" | translate }}
            </a>
        </div>
        <div class="flex flex-column w-full text-base sm:text-lg">
            <a [ngClass]="{
                'fontS flex align-items-end h-2rem': selectedLang === 'EN'}" target="_blank" rel="noopener noreferrer"
                href="https://www.siameseprivilege.com/"
                class="cursor-pointer text-900 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                {{ "header.privilege" | translate }}
            </a>
        </div>
    </div>
    <div class="flex flex-column justify-content-between w-full my-2 gap-3 text-base sm:text-lg">
        <!-- <div class="flex flex-column w-full">
            <div class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="careerMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveCareer,
                    'text-900': !sectionIsActiveCareer
                }">{{ "header.career" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openCareerMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openCareerMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column mt-3 gap-3" *ngIf="openCareerMenu">
                <div (click)="navTo('/career/sa-career')" routerLink="/career/sa-career" routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.career1" | translate }}
                </div>
                <div
                    class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between">
                    {{ "menuHeader.career2" | translate }}
                </div>
            </div>
        </div> -->
        <div class="flex flex-column w-full">
            <div class="cursor-pointer text-color hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between"
                (click)="contactMenu()">
                <div [ngClass]="{
                    'fontS flex align-items-end': selectedLang === 'EN',
                    'text-primary': sectionIsActiveContact,
                    'text-900': !sectionIsActiveContact
                }">{{ "header.contact" | translate }}</div>
                <span class="flex align-items-center">
                    <i *ngIf="!openContactMenu" class="pi pi-plus text-primary" style="font-size: 1rem;"></i>
                    <i *ngIf="openContactMenu" class="pi pi-minus text-primary" style="font-size: 1rem;"></i>
                </span>
            </div>
            <div [ngClass]="{
                'fontS': selectedLang === 'EN'}" class="flex flex-column mt-3 gap-3" *ngIf="openContactMenu">
                <div (click)="navTo('/contact/all')" routerLink="/contact/all"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.contact1" | translate }}
                </div>
                <div (click)="navTo('/contact/report')" routerLink="/contact/report"
                    routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact: true}"
                    class="cursor-pointer text-700 hover:text-primary border-bottom-1 border-300 py-2 flex justify-content-between no-underline">
                    {{ "menuHeader.contact2" | translate }}
                </div>
            </div>
        </div>
    </div>
</div>